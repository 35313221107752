// JavaScript Document
const Cookies = require('js-cookie');
SE.clsGTMTracking = (function () {

    // const timeout_fallback;
    const init = function () {
        let GDPRCookie = Cookies.get('GDPRCookie');
        if (typeof GDPRCookie == "undefined") {
            $('#GDPR-popup').show();
            for (let i = 2; i <= 4; ++i) {
                $(`#cookie-function-${i}`).prop('checked', true).trigger("change");
            }
        } else {

            if (GDPRCookie.indexOf("20") > 0) {
                $("#cookie-function-2").prop('checked', true).trigger("change");
            }
            if (GDPRCookie.indexOf("30") > 0) {
                $("#cookie-function-3").prop('checked', true).trigger("change");
            }
            if (GDPRCookie.indexOf("40") > 0) {
                $("#cookie-function-4").prop('checked', true).trigger("change");
            }
        }
        function removeGoogleAnalyticsCookies() {
            Object.keys(Cookies.get()).forEach((key) => {
                ['_gid', '_ga'].forEach((item) => {
                    if (key.includes(item)) {
                        Cookies.remove(key);
                        if (window.location.hostname.includes('preview.happiness-brussels.com')) {
                            let temp = location.hostname.split('.').reverse();
                            let rootdomain = '.' + temp[1] + '.' + temp[0];
                            Cookies.remove(key, { path: '/', domain: rootdomain });
                        }
                        Cookies.remove(key, { path: '/', domain: `.${window.location.hostname}` });
                    }
                })
            })
        }
        function compareCookie(currentCookie, newCookie) {
            if (typeof currentCookie != "undefined") {
                if (currentCookie != newCookie) {
                    removeGoogleAnalyticsCookies()
                    window.location.reload();
                }
            }
            else {
                removeGoogleAnalyticsCookies()
                window.location.reload();
            }
        }
        $(document).on("click", "#btn-accept-all-cookie", function () {
            let GDPRCookie = Cookies.get('GDPRCookie');
            var cookieVal = "10|20|30|40";
            Cookies.set('GDPRCookie', cookieVal, {
                expires: 360,
                path: '/',
                domain: location.hostname
            });
            $('#GDPR-popup').hide();
            compareCookie(GDPRCookie, cookieVal)
            window.location.reload();
            // SE.clsGTMTracking.GTM_tracking_pageview_FB("/" + window_app.language, document.title);
            return false;
        });
        function get_cookie_function() {
            var arr_cookie_val = [];
            $("input[type='checkbox'][id^='cookie-function-']").each(function (i, e) {
                if ($(e).is(':checked')) {
                    arr_cookie_val.push($(e).val());
                }
            });

            var str_cookie_val = arr_cookie_val.length > 0 ? arr_cookie_val.join("|") : "10";
            return str_cookie_val;
        }
        $(document).on("click", "#btn-accept-cookie", function () {
            var GDPRCookie = Cookies.get('GDPRCookie');
            var cookieVal = get_cookie_function();
            Cookies.set('GDPRCookie', cookieVal, {
                expires: 360,
                path: '/',
                domain: location.hostname
            });
            $('#GDPR-popup').hide();
            compareCookie(GDPRCookie, cookieVal)
            // if(cookieVal.indexOf("40") >= 0){
            //     SE.clsGTMTracking.GTM_tracking_pageview_FB("/"+window_app.language, document.title);
            // }
            return false;
        });
        $(document).on("click", "#btn-update-cookie", function () {
            $('#GDPR-popup').show();
            return false;
        });
        let mapChangeCookieValue = {
            "20": {
                on: 'btn-analytical-on',
                off: 'btn-analytical-off'
            },
            "30": {
                on: 'btn-functional-on',
                off: 'btn-functional-off'
            },
            "40": {
                on: 'btn-tracking-on',
                off: 'btn-tracking-off'
            },
        }
        $(document).on('change', '#GDPR-popup input', function () {
            let realCookie = Cookies.get('GDPRCookie') || '';
            let value = $(this).val();
            let checked = $(this).prop('checked') ? 'on' : 'off';
            if (realCookie.includes('20')) {
                if (window.dataLayer) {
                    dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': 'kharis-cookie-banner', 'CustomEvent - Action': mapChangeCookieValue[value][checked], 'CustomEvent - Label': '' });
                }
            }
        })
    };
    const bind_events_for_ET = function () {
        $(document).on('click', '[et]', function () {
            var tagName = $(this).prop("tagName").toLowerCase();
            var tagURL;
            if (tagName == 'a' && typeof $(this).attr("href") != "undefined") {
                var tag_href = $(this).attr("href");
                var parser = document.createElement('a'); parser.href = tag_href;
                tagURL = parser.href;
            }
            if (tagName != 'a' || (tagName == 'a' && (typeof tagURL == "undefined" || tagURL.indexOf("://") == -1))) {
                var params = $(this).attr('et');
                var arr_params = params.split('_');
                var category = typeof arr_params[0] != "undefined" ? arr_params[0] : '';
                var action = typeof arr_params[1] != "undefined" ? arr_params[1] : 'action';
                var label = typeof arr_params[2] != "undefined" ? arr_params[2] : '';
                var value = typeof arr_params[3] != "undefined" ? arr_params[3] : '';
                if (category != '') {
                    _self.GTM_tracking_event(category, action, label, value);
                }
            }
        });
        setTimeout(function () {
            try {
                _self.GTM_tracking_event('Timeout', 'Seconds', '10000');
            }
            catch (e) { }
        }, 10000);
    };
    const GTM_tracking_event = function (category, action, label) {
        try {
            if (window.dataLayer) {
                dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': category, 'CustomEvent - Action': action, 'CustomEvent - Label': label });
            }
        }
        catch (e) {
            console.log(e);
        }
    };
    const GTM_tracking_pageview = function (page, title) {
        var title = title || document.title;
        try {
            if (window.dataLayer) {
                dataLayer.push({ 'event': 'Custom - PageView', 'CustomPageView - PageURL': page, 'CustomPageView - PageTitle': title });
                //dataLayer.push({ 'event' :  'FB - Custom - PageView',  'FB - CustomPageView - ContentIds' :  page,  'FB - CustomPageView - ContentName' :  title});
            }
        } catch (e) {
            console.log(e);
        }
    };
    const GTM_tracking_pageview_FB = function (page, title) {
        var title = title || document.title;
        try {
            if (window.dataLayer) {
                console.log("GTM_tracking_pageview_FB");
                dataLayer.push({ 'event': 'Custom - PageView - FB', 'CustomPageView - PageURL': page, 'CustomPageView - PageTitle': title });
            }
        } catch (e) {
            console.log(e);
        }
    };
    const GTM_eventConversionGoals = function (action, label) {
        var category = 'ConversionGoals';
        try {
            GTM_tracking_event(category, action, label);
        }
        catch (e) { }
    };
    const GTM_FbPixel_Event_Tracking = function (params) {
        var send_tracking = false;
        var arr_et = ["Home-BecomeSupporter", "Step1-GotoStep2", "Step2-GotoStep4", "Step4-GotoForm", "Form-ConnectFB", "Form-Submit", "Step5-ShareFB"];
        if (arr_et.indexOf(params) >= 0) {
            send_tracking = true;
        }
        if (send_tracking == true) {
            var arr_params = params.split('_');
            var category = typeof arr_params[0] != "undefined" ? arr_params[0] : '';
            var action = typeof arr_params[1] != "undefined" ? arr_params[1] : 'action';
            if (category != '') {
                var params = { content_category: category, content_name: action };
                console.log(params);
                _self.GTM_FbPixel_CustomEvent("trackCustom", "ClickButton", params);
            }
        }
    };
    const GTM_FbPixel_CustomEvent = function (event_type, event_name, event_params) {
        try {
            if (window.dataLayer) {
                event_params = event_params || null;
                dataLayer.push({ 'event': 'FB - Custom - Event', 'FB - CustomEvent - EventType': event_type, 'FB - CustomEvent - EventName': event_name, 'FB - CustomEvent - EventParams': event_params });
            }
        } catch (e) {
            console.log(e);
        }
    };
    const getClientID = function () {
        var result = ga.getAll()[0].get('clientId');
        return result;
    };
    return {
        init,
        GTM_tracking_pageview,
        GTM_tracking_pageview_FB,
        GTM_FbPixel_CustomEvent,
        GTM_tracking_event,
        GTM_eventConversionGoals,
    }
})();
