SE.para_navforslider = {
  obj: '.kharisslider_asNavFor',
}

SE.para_slider = {
    div_obj: '',
    
    div_overplay : ' .slick-current .overlay',
    div_groupslidercta : ' .slick-current .group-slidercta',
    div_carrouseltextcontent: ' .slick-current .carrousel_text_content',

    div_slidecurrent: ' .slick-current ',
    div_slidecurrent_overplay: ' .slick-current .overlay',
    div_slidecurrent_groupslidercta: ' .slick-current .group-slidercta',
    div_slidecurrent_carrouseltextcontent: ' .slick-current .carrousel_text_content',
    div_slidecurrent_text_next: ' .slick-current .button-text-next',
    div_slidecurrent_text_prev: ' .slick-current .button-text-prev',

    div_slidenext: ' .slick-current + .slick-slide ',
    div_slidenext_groupslidercta: ' .slick-current + .slick-slide .group-slidercta',
    div_nocurrent_textcontent: ' .slick-slide:not(.slick-current) .carrousel_text_content',
    div_slidenext_text_next: ' .slick-current + .slick-slide .button-text-next',
    div_text_prev : ' .button-text-prev',
    div_circle_b_next : ' .item.left .circle.circle_b',
    div_circle_s_next: ' .item.left .circle.circle_s',
    div_circle_b_prev : ' .item.right .circle.circle_b',
    div_circle_s_prev: ' .item.right .circle.circle_s',
    current: 0,
    arrowpos_start : 70,
    arrowpos_tabletstart : 55,
    arrowpos_mobilestart : 38,
    arrowpos_center: 0,
    duration: .5,
    ani_hover : null,
    isrunning: false,
    speed: 600
  }

  SE.para_defauts_options = {
    dots: false,
    cssEase: 'linear',
    speed: SE.para_slider.speed,
    slidesToScroll: 1,
    infinite: false,
    swipe: false,
    arrows: false,
  }

  SE.para_defauts_responsive_01 = {
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.5
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.5
        }
      }
    ]
  
  }

  SE.para_defauts_responsive_02 = {
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.5,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.5,
        }
      }
    ]
  
  }

  SE.para_defauts_responsive_03 = {
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5.5
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4.5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 2.5,
        }
      }
    ]
  
  }
  


  