SE.clsCookie = (function () {
    //INIT
    function init() {
        openCookie();
        closeCookie();
    }


    function openCookie() {
        $(document).on('click', '.js-opencookie', function (e) {
            e.preventDefault();
            $('#GDPR-popup').show();
        });
    }

    function closeCookie() {
    
    }

    //RETURN
    return {
        init: init
    }
})();

SE.clsCookie.init();
