SE.clsMenu = (function () {
    // PARA
    var setting = {
        showHeaderBackground: false,
        tl: null

    }
    //INIT
    function init() {
        initEvent();
        ani_init();
        backbutton_init();
    }


    function initEvent() {
        $('#iconmenu-js').click(function () {
            if ($(this).hasClass('open')) {
                hide()
                dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': "kharis-menu", 'CustomEvent - Action': "btn-close", 'CustomEvent - Label': "" });
                $('a.logo[et]').attr('et',"kharis-header|btn-home")
            }
            else {
                show()
                dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': "kharis-header", 'CustomEvent - Action': "btn-menu", 'CustomEvent - Label': "" });
                $('a.logo[et]').attr('et',"kharis-menu|btn-home")
            }
        });

    }

    //ANIMATION
    function ani_init() {

        setting.tl = gsap.timeline({ paused: true, onComplete: ani_complete, onReverseComplete: ani_reverse })
        setting.tl
            .to('#menu-js', .3, { display: 'block', ease: Power0.easeNone })
            .to(".menulist .item", .3, { opacity: 1, top: 0, stagger: .1, ease: Power0.easeNone })
    }

    function ani_complete() {
     
    }

    function ani_reverse() {
  
        if (setting.showHeaderBackground = true) {
            $('.sticky-js').removeClass('sticky-transparent');
        }
        $('#iconmenu-js').removeClass('open');
        $('#header-js').removeClass('open');
    }

    // BACK BUTTONS
    function backbutton_init() {
        $('.js-backbutton-home').click(function (e) {
            dataLayer.push({ 'event': 'Custom - Event', 'CustomEvent - Category': "kharis-header", 'CustomEvent - Action': "btn-back", 'CustomEvent - Label': "" });
            setTimeout(()=>{
                backbutton_run($(this).attr('page'))
            },500)
        });
    }

    function backbutton_run(page) {
        window.location = '/';
        localStorage.setItem('page', page);
    }

    function backbutton_set(page) {
        $('.js-backbutton-home').attr('page', page).removeClass('hide');
    }

    //FUNCTIONS
    function hide() {
        setting.tl.reverse();
    }

    function show() {
        $('#iconmenu-js').addClass('open');
        $('#header-js').addClass('open');
        if (setting.showHeaderBackground = true) {
            $('.sticky-js').addClass('sticky-transparent');
        }
        setting.tl.play();
    }

    //RETURN
    return {
        init: init,
        backbutton_set:backbutton_set
    }
})();

SE.clsMenu.init();
