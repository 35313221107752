import { gsap } from "gsap";
import Scrollbar from 'smooth-scrollbar';
gsap.install(window);
gsap.config({ nullTargetWarn: false });


require('./app/class.menu');
require('./app/paramaters');
require('./app/class.cookie');
require('./app/cls.GTMTracking');

$(document).ready(function () {
    SE.clsGTMTracking.init();
})


var para_fixed = {
    header: $('#header-js'),
    turnon: true,
}

var options = {
    damping: .1,
    alwaysShowTracks: true,
    thumbMinSize: 20,
}


function eventscroll_mobile(){
    var y = $(window).scrollTop();

    // Do something
    if (y >= 10) {
        para_fixed.header.addClass('fixed_header');
    }
    else {
        para_fixed.header.removeClass('fixed_header');
    }

    if ($('body').hasClass('Mobile') || $('body').hasClass('Tablet')) {
        para_fixed.turnon = false;
        if(status) return;
        var scroll = setTimeout(() => {
            $('html, body').animate({scrollTop:400},'50');
            status = true;
        }, 5000);
    }
    
    window.onscroll = function (e) {  
        status = true;
        clearTimeout(scroll);
    } 

    
}
var status = false;
function eventscroll_desktop() {
    $('body').addClass('customscrolling');
    Scrollbar.init(document.querySelector('main'), options).addListener(({ offset }) => {
        var y = offset.y;

        if (y >= 50) {
            para_fixed.header.addClass('fixed_header');
            status = true;
            clearTimeout(scroll);
        }
        else {
            para_fixed.header.removeClass('fixed_header');
        }
    });
   
    if(status) return;
    var scroll = setTimeout(() => {
        Scrollbar.init(document.querySelector('main'), options).scrollTo(0, 400, 500);
        status = true;
    }, 5000);
   
}

function checkscroll_mobile() {
    if ($('body').hasClass('Mobile') || $('body').hasClass('Tablet')) {
        para_fixed.turnon = false;
    }
}


$(window).on('load', function () {
    if ($("#pagehome").length == 0) {
       
        checkscroll_mobile();
        
        if (para_fixed.turnon) {
            eventscroll_desktop();
        }
        else {
            eventscroll_mobile();
            
            $(window).scroll(function () {
                eventscroll_mobile();
            });
        }

    }
});
